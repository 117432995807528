




















































import Vue from "vue";
export default Vue.extend({
	data() {
		return {
			isModification: false
		};
	},
	created() {
		if (this.getUserToken) {
			this.$store
				.dispatch("GET_MY_APPLICANT")
				.then(applicant => {
					this.isModification = true;
				})
				.catch(err => {});
		}
	},
	watch: {
		getUserToken() {
			if (!this.isJoinCurrentClub)
				this.$store
					.dispatch("GET_MY_APPLICANT")
					.then(applicant => {
						this.isModification = true;
					})
					.catch(err => {});
		}
	},
	computed: {
		isJoinCurrentClub() {
			try {
				return (
					this.$store.state.userInformation.clubs.findIndex(
						(x: any) => x._id == this.$store.state.club._id
					) != -1
				);
			} catch (e) {
				return false;
			}
		},
		getUserToken() {
			return this.$store.state.userToken;
		},
		getIsModificationText(): string {
			return this.isModification ? "수정" : "작성";
		},
		getUserInformation() {
			return this.$store.state.userInformation;
		}
	}
});
